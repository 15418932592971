.modal-overlay {
  --visual-viewport-height: 0;

  background-color: rgb(var(--color-scrim) / 60%);
  height: var(--visual-viewport-height);
  left: 0;
  position: fixed;
  top: 0;
  width: 100dvw;
  z-index: var(--elevation-level-3);
}
