.button {
  --button-state-opacity: 0;
  --button-state-gradient-color: rgb(
    var(--button-state-color) / var(--button-state-opacity)
  );

  align-items: center;
  background-image: linear-gradient(
    to right,
    var(--button-state-gradient-color),
    var(--button-state-gradient-color)
  );
  display: inline-flex;
  font-size: var(--typescale-label-large-size);
  font-weight: var(--typescale-label-large-weight);
  height: 2.5rem;
  justify-content: center;
  letter-spacing: var(--typescale-label-large-tracking);
  line-height: var(--typescale-label-large-line-height);
  outline: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  white-space: nowrap;
}

.button:hover {
  --button-state-opacity: var(--state-hover-layer-opacity);
}

.button:active,
.button:focus {
  --button-state-opacity: var(--state-focus-layer-opacity);
}

.button_filled {
  --button-state-color: var(--color-on-primary);

  background-color: rgb(var(--color-primary));
  border-radius: var(--shape-corner-full);
  color: rgb(var(--color-on-primary));
}

.button_outlined {
  --button-state-color: var(--color-primary);

  border: 1px solid rgb(var(--color-outline));
  border-radius: var(--shape-corner-full);
  color: rgb(var(--color-primary));
}

.button_text {
  --button-state-color: var(--color-primary);

  border-radius: var(--shape-corner-full);
  color: rgb(var(--color-primary));
}
