:root {
  --top-app-bar-container-height: 4rem;
}

.top-app-bar {
  font-size: var(--typescale-title-large-size);
  font-weight: var(--typescale-title-large-weight);
  letter-spacing: var(--typescale-title-large-tracking);
  line-height: var(--typescale-title-large-line-height);
  min-height: var(--top-app-bar-container-height);
}
