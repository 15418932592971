.checkbox-icon {
  display: block;
  padding: 0.75rem;
  position: relative;
}

.checkbox-icon::before {
  --checkbox-icon-state-layer-color: transparent;
  --checkbox-icon-state-layer-opacity: 1;
  --checkbox-icon-state-layer-background: rgb(
    var(--checkbox-icon-state-layer-color) /
      var(--checkbox-icon-state-layer-opacity)
  );

  background-color: var(--checkbox-icon-state-layer-background);
  border-radius: var(--shape-corner-full);
  content: "";
  inset: 0.25rem;
  position: absolute;
}

.checkbox-icon:hover::before {
  --checkbox-icon-state-layer-color: var(--color-on-surface);
  --checkbox-icon-state-layer-opacity: var(--state-hover-layer-opacity);
}

.checkbox-icon__icon {
  display: none;
  height: 1.5rem;
  position: relative;
  width: 1.5rem;
}

.checkbox-icon__icon_checked {
  fill: rgb(var(--color-primary));
}
