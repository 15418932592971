.checkbox {
  align-items: center;
  display: inline-flex;
}

.checkbox:focus-within .checkbox-icon::before {
  --checkbox-icon-state-layer-color: var(--color-on-surface);
  --checkbox-icon-state-layer-opacity: var(--state-focus-layer-opacity);
}

.checkbox:has(:checked) .checkbox-icon:hover::before {
  --checkbox-icon-state-layer-color: var(--color-primary);
}

.checkbox:focus-within:has(:checked) .checkbox-icon::before {
  --checkbox-icon-state-layer-color: var(--color-primary);
  --checkbox-icon-state-layer-opacity: var(--state-focus-layer-opacity);
}

.checkbox .checkbox-icon__icon {
  display: block;
}

.checkbox .checkbox-icon__icon_checked {
  display: none;
}

.checkbox:has(:checked) .checkbox-icon__icon {
  display: none;
}

.checkbox:has(:checked) .checkbox-icon__icon_checked {
  display: block;
}
