.card {
  border-radius: var(--shape-corner-medium);
}

.card_elevated {
  background-color: rgb(var(--color-surface-container-low));
  box-shadow: var(--elevation-shadow-level-1);
}

.card_filled {
  /* noop */
}

.card_outlined {
  background-color: rgb(var(--color-surface));
  border: 1px solid rgb(var(--color-outline-variant));
}
