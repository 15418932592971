.icon-button {
  --icon-button-color: rgb(var(--color-on-surface-variant));
  --icon-button-state-rgb: var(--color-on-surface-variant);
  --icon-button-state-color: rgb(
    var(--icon-button-state-rgb) / var(--icon-button-state-opacity)
  );
  --icon-button-state-gradient: linear-gradient(
    to right,
    var(--icon-button-state-color),
    var(--icon-button-state-color)
  );

  color: var(--icon-button-color);
  outline: none;
  padding: 0.75rem;
  position: relative;
}

.icon-button:hover {
  --icon-button-state-opacity: var(--state-hover-layer-opacity);
}

.icon-button:focus {
  --icon-button-state-opacity: var(--state-focus-layer-opacity);
}

.icon-button:disabled {
  --icon-button-state-opacity: 0;

  opacity: var(--state-disabled-layer-opacity);
}

.icon-button::before {
  background-color: var(--icon-button-container-color, transparent);
  background-image: var(--icon-button-state-gradient, none);
  border-radius: var(--shape-corner-full);
  content: "";
  inset: 0.25rem;
  position: absolute;
}

.icon-button_filled {
  --icon-button-color: rgb(var(--color-on-primary));
  --icon-button-container-color: rgb(var(--color-primary));
}

.icon-button .icon {
  height: 1.5rem;
  position: relative;
  width: 1.5rem;
}
