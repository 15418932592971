:root {
  --navigation-bar-height: 5rem;
}

.navigation-bar {
  background-color: rgb(var(--color-surface-container));
  display: flex;
  gap: var(--spacing-2);
  height: var(--navigation-bar-height);
  padding-inline: 0.5rem;
}
