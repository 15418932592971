.viewport-container {
  padding-inline: var(--spacing-container);
}

@supports (
  padding-left: max(env(safe-area-inset-left), var(--spacing-container))
) {
  .viewport-container {
    padding-left: max(env(safe-area-inset-left), var(--spacing-container));
    padding-right: max(env(safe-area-inset-right), var(--spacing-container));
  }
}
