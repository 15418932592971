.menu {
  background-color: rgb(var(--color-surface-container));
  border-radius: var(--shape-corner-extra-small);
  box-shadow: var(--elevation-shadow-level-2);
  max-width: 17.5rem;
  min-width: 7rem;
  outline: none;
  padding-bottom: var(--spacing-2);
  padding-top: var(--spacing-2);
}

.menu__item {
  align-items: center;
  display: flex;
  gap: var(--spacing-3);
  height: 3rem;
  outline: none;
  padding-left: var(--spacing-3);
  padding-right: var(--spacing-3);
  white-space: nowrap;
}

.menu__item:hover,
.menu__item:focus {
  background-color: rgb(var(--color-surface-container-highest));
}

.menu__item-icon {
  --menu-item-icon-size: 1.5rem;

  flex-shrink: 0;
  height: var(--menu-item-icon-size);
  width: var(--menu-item-icon-size);
}
