.snackbar {
  align-items: center;
  background-color: rgb(var(--color-inverse-surface));
  border-radius: var(--shape-corner-extra-small);
  box-shadow: var(--elevation-shadow-level-3);
  display: flex;
}

.snackbar__text {
  color: rgb(var(--color-inverse-on-surface));
  flex: 1;
  font-size: var(--typescale-body-medium-size);
  font-weight: var(--typescale-body-medium-weight);
  letter-spacing: var(--typescale-body-medium-tracking);
  line-height: var(--typescale-body-medium-line-height);
  padding: 0.875rem var(--spacing-4);
}

.snackbar__text:not(:last-child) {
  padding-inline-end: var(--spacing-1);
}

.snackbar__close-button {
  color: rgb(var(--color-inverse-on-surface));
}
