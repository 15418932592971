.navigation-bar-item {
  --navigation-bar-item-icon-container-state-color: rgb(
    var(--color-on-surface) /
      var(--navigation-bar-item-icon-container-state-opacity)
  );
  --navigation-bar-item-icon-container-state-gradient: linear-gradient(
    to right,
    var(--navigation-bar-item-icon-container-state-color),
    var(--navigation-bar-item-icon-container-state-color)
  );

  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  justify-content: center;
  outline: none;
  padding-block: var(--spacing-3) var(--spacing-4);
}

.navigation-bar-item[aria-selected="true"] {
  --navigation-bar-item-icon-container-background: rgb(
    var(--color-secondary-container)
  );
}

.navigation-bar-item:hover {
  --navigation-bar-item-icon-container-state-opacity: var(
    --state-hover-layer-opacity
  );
}

.navigation-bar-item:focus {
  --navigation-bar-item-icon-container-state-opacity: var(
    --state-focus-layer-opacity
  );
}

.navigation-bar-item__icon-container {
  background-color: var(
    --navigation-bar-item-icon-container-background,
    transparent
  );
  background-image: var(
    --navigation-bar-item-icon-container-state-gradient,
    none
  );
  border-radius: var(--shape-corner-large);
  display: grid;
  height: 2rem;
  width: 4rem;
}

.navigation-bar-item__icon-container .icon {
  height: 1.5rem;
  place-self: center;
  width: 1.5rem;
}

.navigation-bar-item__label {
  font-size: var(--typescale-label-medium-size);
  font-weight: var(--typescale-label-medium-weight);
  letter-spacing: var(--typescale-label-medium-tracking);
  line-height: var(--typescale-label-medium-line-height);
}
