.text-field {
  /* noop */
}

.text-field :where(.input, .textarea) {
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-field .textarea {
  display: block;
}

.text-field_filled {
  --text-field-active-indicator-color: var(--color-on-surface-variant);
  --text-field-active-indicator-size: 1px;
  --text-field-state-color: var(--color-on-surface);
  --text-field-state-opacity: 0;
  --text-field-state-gradient-color: rgb(
    var(--text-field-state-color) / var(--text-field-state-opacity)
  );
}

.text-field_filled:hover {
  --text-field-state-opacity: var(--state-hover-layer-opacity);
}

.text-field_filled:focus-within {
  --text-field-active-indicator-color: var(--color-primary);
  --text-field-active-indicator-size: 3px;
}

.text-field__container {
  position: relative;
}

.text-field_filled .text-field__container {
  background-color: rgb(var(--color-surface-container-highest));
  background-image: linear-gradient(
    to right,
    var(--text-field-state-gradient-color),
    var(--text-field-state-gradient-color)
  );
  border-radius: var(--shape-corner-extra-small) var(--shape-corner-extra-small)
    0 0;
  transition-duration: var(--motion-duration-short-2);
  transition-property: all;
  transition-timing-function: var(--motion-easing-standard);
}

.text-field_filled .text-field__container::before {
  background-color: rgb(var(--text-field-active-indicator-color));
  bottom: 0;
  content: "";
  height: var(--text-field-active-indicator-size);
  inset-inline: 0;
  position: absolute;
  transition-duration: var(--motion-duration-short-2);
  transition-property: background-color, height;
  transition-timing-function: var(--motion-easing-standard);
}

.text-field_filled .text-field__container:has(.textarea) {
  min-height: 3.5rem;
}

.text-field_filled .input {
  border-radius: var(--shape-corner-extra-small) var(--shape-corner-extra-small)
    0 0;
  height: 3.5rem;
  padding-block: 4px;
}

.text-field_filled:has(.label) .input {
  padding-top: 1.5rem;
}

.text-field_filled .textarea {
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
}

.text-field_filled:has(.label) .textarea {
  border-top: 1.5rem solid transparent;
}

.text-field_filled .label {
  color: rgb(var(--color-on-surface-variant));
  font-size: var(--typescale-body-small-size);
  font-weight: var(--typescale-body-small-weight);
  left: 1rem;
  letter-spacing: var(--typescale-body-small-tracking);
  line-height: var(--typescale-body-small-line-height);
  position: absolute;
  top: 0.5rem;
  transition-duration: var(--motion-duration-short-2);
  transition-property: color, top;
  transition-timing-function: var(--motion-easing-standard);
  z-index: 1;
}

.text-field_filled:focus-within .label {
  color: rgb(var(--color-primary));
}

.text-field:not(:focus-within):has(
    :where(
      .input[value=""]:not(:autofill),
      .textarea[placeholder=" "]:placeholder-shown:not(:autofill)
    )
  )
  .label {
  font-size: var(--typescale-body-large-size);
  font-weight: var(--typescale-body-large-weight);
  left: 1rem;
  letter-spacing: var(--typescale-body-large-tracking);
  line-height: var(--typescale-body-large-line-height);
  top: 1rem;
}

.text-field:not(:focus-within):has(:where(.input, .textarea):blank) .label {
  font-size: var(--typescale-body-large-size);
  font-weight: var(--typescale-body-large-weight);
  left: 1rem;
  letter-spacing: var(--typescale-body-large-tracking);
  line-height: var(--typescale-body-large-line-height);
  top: 1rem;
}

.text-field_filled:has(:required) .label::after {
  content: "*";
}

.text-field__supporting-text {
  display: block;
  font-size: var(--typescale-body-small-size);
  font-weight: var(--typescale-body-small-weight);
  letter-spacing: var(--typescale-body-small-tracking);
  line-height: var(--typescale-body-small-line-height);
  margin: 0.25rem 1rem 0;
  min-height: 1lh;
}
