.radio {
  align-items: center;
  display: flex;
}

.radio .radio-icon:hover::before {
  --radio-icon-state-layer-color: var(--color-on-surface);
  --radio-icon-state-layer-opacity: var(--state-hover-layer-opacity);
}

.radio:focus-within .radio-icon::before {
  --radio-icon-state-layer-color: var(--color-on-surface);
  --radio-icon-state-layer-opacity: var(--state-focus-layer-opacity);
}

.radio:has(:checked) .radio-icon:hover::before {
  --radio-icon-state-layer-color: var(--color-primary);
}

.radio:focus-within:has(:checked) .radio-icon::before {
  --radio-icon-state-layer-color: var(--color-primary);
  --radio-icon-state-layer-opacity: var(--state-focus-layer-opacity);
}

.radio .radio-icon__icon {
  display: block;
}

.radio .radio-icon__icon_checked {
  display: none;
}

.radio:has(:checked) .radio-icon__icon {
  display: none;
}

.radio:has(:checked) .radio-icon__icon_checked {
  display: block;
}
