.card-content {
  font-size: var(--typescale-body-medium-size);
  font-weight: var(--typescale-body-medium-weight);
  letter-spacing: var(--typescale-body-medium-tracking);
  line-height: var(--typescale-body-medium-line-height);
  padding: var(--spacing-4);
}

.card-content__title {
  font-size: var(--typescale-body-large-size);
  font-weight: var(--typescale-body-large-weight);
  letter-spacing: var(--typescale-body-large-tracking);
  line-height: var(--typescale-body-large-line-height);
}

.card-content__subhead {
  color: rgb(var(--color-on-surface-variant));
  font-size: var(--typescale-body-medium-size);
  font-weight: var(--typescale-body-medium-weight);
  letter-spacing: var(--typescale-body-medium-tracking);
  line-height: var(--typescale-body-medium-line-height);
}
