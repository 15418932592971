.tooltip {
  background-color: rgb(var(--color-inverse-surface));
  border-radius: var(--shape-corner-extra-small);
  color: rgb(var(--color-inverse-on-surface));
  font-size: var(--typescale-body-small-size);
  font-weight: var(--typescale-body-small-weight);
  letter-spacing: var(--typescale-body-small-tracking);
  line-height: var(--typescale-body-small-line-height);
  padding: var(--spacing-1) var(--spacing-2);
}
