:root {
  --fab-container-height: 3.5rem;
}

.fab {
  --fab-background: var(--color-surface-container-high);
  --fab-color: var(--color-primary);
  --fab-elevation: var(--elevation-shadow-level-3);
  --fab-state-layer-rgb: var(--color-primary);
  --fab-state-layer-color: rgb(
    var(--fab-state-layer-rgb) / var(--fab-state-layer-opacity)
  );
  --fab-state-layer: linear-gradient(
    to right,
    var(--fab-state-layer-color),
    var(--fab-state-layer-color)
  );

  align-items: center;
  background-color: rgb(var(--fab-background));
  background-image: var(--fab-state-layer, none);
  border-radius: var(--shape-corner-large);
  box-shadow: var(--fab-elevation);
  color: rgb(var(--fab-color));
  display: inline-flex;
  font-size: var(--typescale-label-large-size);
  font-weight: var(--typescale-label-large-weight);
  gap: var(--spacing-3);
  letter-spacing: var(--typescale-label-large-tracking);
  line-height: var(--typescale-label-large-line-height);
  outline: none;
  padding: 1rem;
  white-space: nowrap;
  z-index: var(--elevation-level-1);
}

.fab:hover {
  --fab-elevation: var(--elevation-shadow-level-4);
  --fab-state-layer-opacity: var(--state-hover-layer-opacity);
}

.fab:focus {
  --fab-elevation: var(--elevation-shadow-level-3);
  --fab-state-layer-opacity: var(--state-focus-layer-opacity);
}

.fab_primary {
  --fab-background: var(--color-primary-container);
  --fab-color: var(--color-on-primary-container);
  --fab-state-layer-rgb: var(--color-on-primary-container);
}

.fab .icon {
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
}
